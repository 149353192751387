import axios from "axios";
import AuthStore from "@/store/auth-store";

export default class EmployeeService {
  API_URL = process.env.VUE_APP_API_URL;

  async upload(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    const result = await axios.post(
      `${this.API_URL}/employee/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${AuthStore.getToken()}`,
        },
      }
    );
    return result.data;
  }
}
