<template>
  <popup-modal ref="popup">
    <div class="modal-dialog">
      <div class="modal-content px-5" style="max-height: 90vh">
        <div class="modal-body scrollable">
          <!--                Heading-->
          <div class="d-flex align-items-center justify-content-center py-2">
            <!--                Title-->
            <span class="h4 text-primary m-0">Report!</span>
          </div>

          <!--                    Description-->
          <div :class="`d-flex align-items-center py-2 `">
            <div v-if="data" class="">
              <div class="mb-4">
                <div class="h6 mb-3">
                  Success Count:
                  <span class="text-primary">{{
                    data.data.noOfEmployees
                  }}</span>
                </div>

                {{}}
                <!--                    Failed-->
                <template v-if="data.data.failedEmployees.length > 0">
                  <div class="h6 mb-3">List of Failed emails:</div>
                  <ol type="1">
                    <li
                      v-for="(email, index) in data.data.failedEmployees"
                      v-bind:key="index"
                      class="h6 mb-3 text-muted"
                    >
                      {{ email }}
                    </li>
                  </ol>
                </template>

                <!--                    Duplicate-->
                <template v-if="data.data.duplicateEmployees.length > 0">
                  <div class="h6 mb-3">List of Duplicate emails:</div>
                  <ol type="1">
                    <li
                      v-for="(email, index) in data.data.duplicateEmployees"
                      v-bind:key="index"
                      class="h6 mb-3 text-muted"
                    >
                      {{ email }}
                    </li>
                  </ol>
                </template>
              </div>
            </div>
          </div>

          <!--              Buttons-->
          <div class="row py-2">
            <div class="col text-center">
              <button
                @click="_confirm"
                :class="`btn btn-primary w-50`"
                data-dismiss="modal"
              >
                {{ okButton }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </popup-modal>
</template>
<script>
import { defineComponent } from "vue";
import PopupModal from "./PopupModal.vue";

export default defineComponent({
  name: "NotificationModal",

  components: {
    PopupModal,
  },

  data: () => ({
    // Parameters that change depending on the type of dialogue

    // Private variables
    okButton: "Ok",
    resolvePromise: undefined,
    rejectPromise: undefined,
    data: {},
  }),

  methods: {
    async show(opts = {}) {
      this.data = opts.data;
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
      this.managerId = 0;
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      this.managerId = 0;
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
});
</script>
