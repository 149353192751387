
import { Options, Vue } from "vue-class-component";
import EmployeeService from "@/services/employee-service";
import NotificationModal from "@/components/modals/NotificationModal.vue";
const employeeService = new EmployeeService();
import Breadcrumb from "@/components/Breadcrumb.vue";

@Options({
  name: "EmplyeesUpload",
  components: {
    NotificationModal,
    Breadcrumb,
  },
  data() {
    return {};
  },
})
export default class EmployeesUpload extends Vue {
  protected file: any;
  isUploaded = false;
  isError = false;
  showModal = false;
  upload = false;
  loading = false;

  crumbs: any = ["Dashboard", "Employees", "Bulk Upload Employee"];

  $refs!: {
    notificationModal: HTMLFormElement;
    uploadEmployee: any;
  };

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  currentUrl: string =
    location.toString().split("/")[0] + "/employee-upload-templates.zip";

  response = "";
  async uploadEmployees() {
    this.isError = false;
    this.isUploaded = false;
    this.loading = true;
    this.file = this.$refs.uploadEmployee;
    try {
      this.response = await employeeService.upload(this.file.files[0]);
      this.isUploaded = true;
      await this.$refs.notificationModal.show({
        data: this.response,
      });

      this.file.value = null;
    } catch (e) {
      this.isError = true;
    }
    this.loading = false;
  }
}
